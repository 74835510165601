import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./bannerIvi.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Оформить карту"

type BannerProps = {
  orderNum?: string
}

export default function Banner({ orderNum }: BannerProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Img alt="bg image" className={styles.img} />
      <Container className={styles.container}>
        <h1 className={styles.head}>
          Оформи карту <br className="d-md-none" /> Халва
        </h1>
        <p className={styles.desc}>
          и получи 4 месяца <br className="d-md-none" /> доступа в онлайн-
          <br />
          кинотеатр ivi в подарок
        </p>
        <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
