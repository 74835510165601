import React from "react"

import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/base"
import Header from "../components/Ivi/Header"
import Banner from "../components/Ivi/Banner"
import WaysToGetCard from "../components/WaysToGetCard"
import Footer from "../components/Footer"
import IviSteps from "../components/Ivi/Steps"
import { PageData } from "../interfaces/pageProps"
import { FormPKW } from "../components/FormPKW"
import { getPageData } from "../helpers/getPageData"

export default function IviPage({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)

  return (
    <Layout noIndex={noIndex}>
      <Header phones={phones} />
      <Banner orderNum="1" />
      <IviSteps orderNum="2" />
      <WaysToGetCard orderNum="3" />
      <FormPKW
        longForm
        dataLayerName="shortPersonalForm"
        title="Оформить заявку"
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="4"
        subTitle="Заберите его в ближайшем офисе Совкомбанка"
        emptyForm={false}
      />
      <Footer shortText={ligal} phones={phones} />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/ivi/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
