import React from "react"
import Container from "@ecom/ui/components/Container"
import Box from "@material-ui/core/Box"

import * as styles from "./steps.module.scss"

import icon_press_button from "./img/icon_press_button.svg"
import icon_clipboard from "./img/icon_clipboard.svg"
import icon_card from "./img/icon_card.svg"
import icon_sms from "./img/icon_sms.svg"
import icon_promo_code from "./img/icon_promo_code.svg"

const items = [
  {
    icon: icon_press_button,
    title: "1. Нажми на кнопку",
    text: "«Оформить карту»",
  },
  {
    icon: icon_clipboard,
    title: "2. Заполни анкету",
    text: "Среднее время заполнения 2 минуты",
  },
  {
    icon: icon_card,
    title: "3. Получи карту",
    text: "и соверши покупку от 1 ₽ в любом магазине",
  },
  {
    icon: icon_sms,
    title: "4. Промокод с доступом",
    text: "поступит в СМС в течение 10 дней",
  },
  {
    icon: icon_promo_code,
    title: "5. Активируй промокод",
    text: "по ссылке из СМС с промокодом ivi.ru/cert/halva",
  },
]

type IviStepsProps = {
  orderNum?: string
}

export default function IviSteps({ orderNum }: IviStepsProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.container}>
        <Box
          display="flex"
          justifyContent="start"
          flexWrap="wrap"
          component="ul"
          className={styles.box}
        >
          {items.map(({ icon, title, text }, i) => (
            <li key={i} className={styles.item}>
              <img alt="icon" src={icon} className={styles.icon} />
              <div className={styles.textBlock}>
                <p className={styles.title}>{title}</p>
                <p className={styles.text}>{text}</p>
              </div>
            </li>
          ))}
        </Box>
      </Container>
    </section>
  )
}
