import React from "react"

import Header from "@ecom/ui/components/Header/Halva"

import logo_ivi from "./img/logo_ivi.png"

import * as styles from "./header.module.scss"

export default function HeaderIvi({ phones }: { phones?: string[] }) {
  const rightPart = <img alt="logo ivi" src={logo_ivi} className={styles.logo} />

  return <Header right={rightPart} PhoneProps={{ phones }} />
}
